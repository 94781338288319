import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import {
  ComposableMap,
  Geographies,
  Geography
} from 'react-simple-maps';
import './App.css';

const worldAtlasUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

const Dashboard = () => {
  return (
    <Container fluid className="dashboard">
      <Row className="mb-4">
        <Col xs={12}>
          <Card className="main-card">
            <Card.Header>Main Map</Card.Header>
            <Card.Body>
              <ComposableMap 
                projectionConfig={{ scale: 180, rotate: [30, 0, 0] }} 
                width={1920}
                height={500}
                style={{ maxWidth: "100%", maxHeight: "100%" }}>
                <Geographies geography={worldAtlasUrl}>
                  {({ geographies }) =>
                    geographies.map(geo => (
                      <Geography key={geo.rsmKey} geography={geo} fill="#000000" stroke="#BD03F7" />
                    ))
                  }
                </Geographies>
              </ComposableMap>
              <Card className="info-card semi-transparent-frame">
                <Card.Header>Information</Card.Header>
                <Card.Body>
                  Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Sed felis eget velit aliquet sagittis id consectetur purus ut. Amet consectetur adipiscing elit duis. Mi in nulla posuere sollicitudin aliquam. Tristique sollicitudin nibh sit amet. Nisi porta lorem mollis aliquam. Felis eget nunc lobortis mattis aliquam faucibus. Eget velit aliquet sagittis id consectetur purus ut. Posuere lorem ipsum dolor sit. Nec feugiat in fermentum posuere urna nec tincidunt. Velit egestas dui id ornare arcu. Pellentesque habitant morbi tristique senectus et netus et malesuada fames. In pellentesque massa placerat duis ultricies. Ut faucibus pulvinar elementum integer enim neque. In nibh mauris cursus mattis molestie a iaculis. In ornare quam viverra orci sagittis eu volutpat odio. Sit amet tellus cras adipiscing enim eu turpis egestas pretium.
                </Card.Body>
              </Card>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="card-container flex-grow-1">
        <Col md={6} className="mb-4 d-flex">
          <Card className="feeds-card semi-transparent-frame flex-grow-1">
            <Card.Header>Feeds</Card.Header>
            <Card.Body>
              <Card className="content-card mb-3">
                <Card.Header>Content 1</Card.Header>
                <Card.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Scelerisque viverra mauris in aliquam sem fringilla ut morbi tincidunt. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Et molestie ac feugiat sed lectus vestibulum mattis. Sagittis eu volutpat odio facilisis mauris sit amet massa vitae. Ut tortor pretium viverra suspendisse potenti nullam ac. Sed nisi lacus sed viverra tellus in. Nibh ipsum consequat nisl vel pretium lectus quam. Dui id ornare arcu odio ut sem nulla. Nibh tellus molestie nunc non blandit massa enim nec. Nulla posuere sollicitudin aliquam ultrices sagittis orci a. Senectus et netus et malesuada fames ac. Semper feugiat nibh sed pulvinar proin gravida hendrerit lectus a.
            </Card.Body>
          </Card>
          <Card className="content-card">
            <Card.Header>Content 2</Card.Header>
            <Card.Body>
                  Amet aliquam id diam maecenas ultricies mi eget. Leo vel orci porta non pulvinar neque laoreet. Nec dui nunc mattis enim ut. Arcu dui vivamus arcu felis bibendum ut tristique et. Diam donec adipiscing tristique risus nec feugiat. Sed euismod nisi porta lorem mollis. Tellus rutrum tellus pellentesque eu tincidunt. Tristique senectus et netus et. Sed odio morbi quis commodo. Non consectetur a erat nam. Ac auctor augue mauris augue neque gravida in. Et tortor consequat id porta nibh venenatis. Cursus turpis massa tincidunt dui ut ornare lectus. Consectetur purus ut faucibus pulvinar elementum. Donec adipiscing tristique risus nec feugiat in fermentum posuere urna. Quis enim lobortis scelerisque fermentum dui faucibus in ornare quam.
            </Card.Body>
          </Card>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-4 d-flex">
          <Card className="alerts-card semi-transparent-frame flex-grow-1">
            <Card.Header>Alerts</Card.Header>
            <Card.Body>
          <Card className="content-card mb-3">
            <Card.Header>Alert Content 1</Card.Header>
            <Card.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque id enim sit amet tellus ultrices, at ultricies tellus lacinia. Sed eget metus eget sapien fermentum faucibus. Proin ut purus justo. Donec bibendum auctor mi eget luctus. Donec vel mauris sed purus sollicitudin venenatis at quis elit. Ut id congue eros, eget rhoncus felis. Pellentesque ac risus sit amet quam sollicitudin cursus non id elit.
            </Card.Body>
          </Card>
          <Card className="content-card">
            <Card.Header>Alert Content 2</Card.Header>
            <Card.Body>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
            </Card.Body>
          </Card>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

function App() {
  return (
    <div className="App">
      <Dashboard />
    </div>
  );
}

export default App;
